.additional-information{
    h2{
        position: relative;
        padding: 12px 0;
        span{
            padding: 0;
            text-transform: uppercase;
        }
        &:after {
            content: '';
            background-image: url('https://workforcecenter.slu.edu/images/background-pattern.png');
            background-repeat: repeat;
            background-size: 250px;
            height: 100%;
            left: -300%;
            position: absolute;
            top: 0;
            width: 700%;
            z-index: -1;
        }
    }
    .block-grid{
        display: flex;
        flex-wrap: wrap;
        h3{
            color:$primary-color;
            font-family:$display;
            font-size: 20px;
            font-weight: 500;
        }
        .block-col {
            width: 50%;
            overflow: hidden;
            @media screen and (max-width:$break){
                width: 100%;
            }
            p{
                padding-right:20px;
            }
        }
    }
}