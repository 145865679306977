/*Delete everything above this line after Destiny One v2019.1 (Magneto) update*/

/*
    Note that this file is not actually being referenced in the public site.  To include this file, upload it as the CSS file at SV > Marketing > Public View > Configuration.

  This file is structured according to SMACSS approach.
  See http://smacss.com/book/categorizing

  All styles are on top of lib/bootstrap.css
*/

/* IMPORTANT: By mobile-first approach, phone view sets the base. If required, phone styles can be redefined for larger screen sizes below.*/

/*
  The Bootswatch Yeti theme (http://bootswatch.com).
*/

// @import url(/css/font-awesome.min.css);

// @import url(/css/font-awesom.css);

// @import url("bootswatch.yeti.min.css");

// @import url('https://fonts.googleapis.com/css?family=Open+Sans:700');

/* Archivo Narrow */
// @font-face {
//   font-family: 'Archivo Narrow';
//   src: url('../fonts/ArchivoNarrow-Regular.eot');
//   src: url('../fonts/ArchivoNarrow-Regular.woff2') format('woff2'),
//        url('../fonts/ArchivoNarrow-Regular.woff') format('woff'),
//        url('../fonts/ArchivoNarrow-Regular.eot?#iefix') format('embedded-opentype');
//   font-weight: normal;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'Archivo Narrow';
//   src: url('../fonts/ArchivoNarrow-Bold.eot');
//   src: url('../fonts/ArchivoNarrow-Bold.woff2') format('woff2'),
//        url('../fonts/ArchivoNarrow-Bold.woff') format('woff'),
//        url('../fonts/ArchivoNarrow-Bold.eot?#iefix') format('embedded-opentype');
//   font-weight: bold;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'Archivo Narrow';
//   src: url('../fonts/ArchivoNarrow-BoldItalic.eot');
//   src: url('../fonts/ArchivoNarrow-BoldItalic.woff2') format('woff2'),
//        url('../fonts/ArchivoNarrow-BoldItalic.woff') format('woff'),
//        url('../fonts/ArchivoNarrow-BoldItalic.eot?#iefix') format('embedded-opentype');
//   font-weight: bold;
//   font-style: italic;
// }
// @font-face {
//   font-family: 'Archivo Narrow';
//   src: url('../fonts/ArchivoNarrow-Italic.eot');
//   src: url('../fonts/ArchivoNarrow-Italic.woff2') format('woff2'),
//        url('../fonts/ArchivoNarrow-Italic.woff') format('woff'),
//        url('../fonts/ArchivoNarrow-Italic.eot?#iefix') format('embedded-opentype');
//   font-weight: normal;
//   font-style: italic;
// }


// /* Brandon Grotesque */
// @font-face {
//   font-family: 'Brandon Grotesque';
//   src: url('../fonts/BrandonGrotesque-Medium.eot');
//   src: url('../fonts/BrandonGrotesque-Medium.woff2') format('woff2'),
//        url('../fonts/BrandonGrotesque-Medium.woff') format('woff'),
//        url('../fonts/BrandonGrotesque-Medium.eot?#iefix') format('embedded-opentype');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Brandon Grotesque';
//   src: url('../fonts/BrandonGrotesque-Black.eot');
//   src: url('../fonts/BrandonGrotesque-Black.woff2') format('woff2'),
//        url('../fonts/BrandonGrotesque-Black.woff') format('woff'),
//        url('../fonts/BrandonGrotesque-Black.eot?#iefix') format('embedded-opentype');
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Brandon Grotesque';
//   src: url('../fonts/BrandonGrotesque-MediumItalic.eot');
//   src: url('../fonts/BrandonGrotesque-MediumItalic.woff2') format('woff2'),
//        url('../fonts/BrandonGrotesque-MediumItalic.woff') format('woff'),
//        url('../fonts/BrandonGrotesque-MediumItalic.eot?#iefix') format('embedded-opentype');
//   font-weight: normal;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Brandon Grotesque';
//   src: url('../fonts/BrandonGrotesque-BlackItalic.eot');
//   src: url('../fonts/BrandonGrotesque-BlackItalic.woff2') format('woff2'),
//        url('../fonts/BrandonGrotesque-BlackItalic.woff') format('woff'),
//        url('../fonts/BrandonGrotesque-BlackItalic.eot?#iefix') format('embedded-opentype');
//   font-weight: bold;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Crimson Text';
//   src: url('CrimsonText-Roman.eot');
//   src: url('CrimsonText-Roman.woff2') format('woff2'),
//        url('CrimsonText-Roman.woff') format('woff'),
//        url('CrimsonText-Roman.eot?#iefix') format('embedded-opentype');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Crimson Text';
//   src: url('CrimsonText-Italic.eot');
//   src: url('CrimsonText-Italic.woff2') format('woff2'),
//        url('CrimsonText-Italic.woff') format('woff'),
//        url('CrimsonText-Italic.eot?#iefix') format('embedded-opentype');
//   font-weight: normal;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Crimson Text';
//   src: url('CrimsonText-SemiboldItalic.eot');
//   src: url('CrimsonText-SemiboldItalic.woff2') format('woff2'),
//        url('CrimsonText-SemiboldItalic.woff') format('woff'),
//        url('CrimsonText-SemiboldItalic.eot?#iefix') format('embedded-opentype');
//   font-weight: 600;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Crimson Text';
//   src: url('CrimsonText-Semibold.eot');
//   src: url('CrimsonText-Semibold.woff2') format('woff2'),
//        url('CrimsonText-Semibold.woff') format('woff'),
//        url('CrimsonText-Semibold.eot?#iefix') format('embedded-opentype');
//   font-weight: 600;
//   font-style: normal;
// }


body {
  overflow-x: hidden;
  font-size: 15px;
}

p {
  font-size: 17px;
}
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Open Sans', sans-serif;
}

#studentProfileDirectBillingAccountContainer {
  display: none !important;
}

.featuredImage.noImage {
  background-color: #eeeeee;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255, 255, 255, .2)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255, 255, 255, .2)), color-stop(.75, rgba(255, 255, 255, .2)), color-stop(.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
  background-image: -ms-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
  -webkit-background-size: 25px 25px;
  -moz-background-size: 25px 25px;
  background-size: 25px 25px;
}
.navbar-inverse{
  background: #003da5;
}

.infographcontainer {
  width: 100%;
  height: 125px;
  margin: 2px;
  display: flex;
}

#pagenewsletter input[type="radio"], #pagenewsletter input[type="checkbox"] {
  opacity: inherit;
}

#pagenewsletter label:after {
  content: "";
}

.infograph {
  border: 5px solid #5BC2E7;
  width: 75%;
  height: 100%;
  display: inline-block;
  color: white;
  background: #003B5C;
  text-align: center;
  padding: 6px;
}

.infograph2 {
  border: 5px solid #5BC2E7;
  width: 75%;
  height: 100%;
  display: inline-block;
  color: white;
  background: #003B5C;
  text-align: center;
  padding: 15px;
}

.notavailable {
  font-size: 17px;
  color: #003DA5;
}

.removepadding {
  padding: 8px;
}

.teamimage {
  max-width: 125px;
  max-height: 175px;
  border-radius: 50px;
}

.row.buttons.regPolicies::after {
  content: "If you have any questions or just need help with your registration, please contact us at 314.977.3226 or info@workforcecenter.slu.edu. Thank you.";
  text-indent: 15px;
}

.underline {
  text-decoration: underline;
}

#partnerScholarship {
  float: left;
}

.btn-secondary {
  border: 1px solid #bfbfbf;
  padding: .275rem .75rem;
}

.ulMenu {
  list-style-type: none;
}

.btn-partner:hover,
.btn-partner:focus {
  background-color: #609ac5 !important;
}

.btn-partner {
  width: 226px;
  text-align: left;
}

#partnerMenu {
  background: #D9EFFF;
  border: 1px solid #bfbfbf;
  margin-bottom: 20px;
  padding: 8px;
}

.partner-dropdown {
  background-color: #ffffff !important;
  overflow: hidden;
  overflow-y: scroll;
  height: 100px;
  font-weight: bold !important;
}

#partnershipBenefits {
    height: 285px;
  }

#PartnershipEdcor {
  padding-top: 25px;
}

#becomePartner {
  padding: 20px;
  display: block;
  margin-bottom: 50px;
}

#becomeTitle {
  float: left;
  width: 45%;
}

#becomeContent {
  float: left;
  width: 52%;
  display: block;
}

#benefitPartner {
  padding: 20px;
  display: block;
  margin-bottom: 70px;
  margin-top: 10px;
}

#benefitTitle {
  float: left;
  width: 45%;
  display: block;
}

#benefitContent {
  float: left;
  width: 52%;
  display: block;
}

#certificateColumn {
  float: left;
  width: 30%;
  height: 100%;
  border-right-width: 1px;
  border-right-style: solid;
  margin: 10px;
}

#columnMargin {
  margin: 5px;
}

.courseProfileSectionAvailability_enrollment_closed {
  color: green;
}

.courseAvailability_enrollment_closed {
  display: none;
}

.courseAvailability_available {
  display: none;
}

#trainingColumn {
  float: left;
  width: 30%;
  height: 100%;
  border-right-width: 1px;
  border-right-style: solid;
  margin: 10px;
}

#mobileColumn {
  float: left;
  width: 30%;
  margin: 10px;
}

.panel-body .row{
  margin: 0 !important;
}

.smallText {
  font-size: 8px;
}

.assessh1 {
  text-align: center;
}

.center {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}

#studentProfileEducationBackgroundContainer {
  display: none;
}

#studentProfileEducationHeader {
  display: none;
}

#telephoneExtWrapper {
  display: none;
}

.grouptext {
  color: #000;
}

#salutation {
  display: none;
}

#salutation label {
  display: none;
}

#studentInfoMiddleName {
  display: none;
}

#studentInfoSuffix {
  display: none;
}

.newsItemStory {
  padding-bottom: 15px;

}

#workforceCenterContactWrapper {
  background: #003DA5;
}

.footer1{
  padding-top: 10px;
}

#courseSearchResult_container .nav.nav-tabs {
  display: none;
}

.catalogSearchResults .courseName {
  display: inline-block;
}

.catalogSearchResults .courseCode {
  display: inline-block
}

.navbar {
  font-size: 16px;
  margin-bottom: 0px;
}

.disclaimer,
.disclaimer:hover,
.disclaimer:visited {
  color: white;
  font-size: 12px;
}

.nav.navbar-nav li.hidden-rightborder,
.navbar-nav>li>.dropdown-menu li {
  border-right: none
}

.navbar-dark.bg-blue {
  background-color: #003DA5 !important;
  border-color: #003DA5;
}

.footerSiteMap h5 {
  color: #ffffff;
}

.carousel-control .glyphicon,
.glyphicon-shopping-cart {
  color: white;
}


.slu_workforce-center-contact, .slu_workforce-center-logo {
  max-width: 100%;
  background-size: 100%;
  background-image: url('/images/header_sprite.png');
}

.slu_workforce-center-contact {
  background-position: 0 0%;
  background-size: 100%;
}

.slu_workforce-center-logo {
  background-position: 0 100%;
  background-size: 100%;
}

#workforceCenterContactWrapper {
  display: none;
  height: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 100px;
  padding-right: 100px;
}

#workforceCenterLogoWrapper {
  display: none;
}

#pagePublicHome .workforce-center-header {
  display: none;
}

.workforce-center-header {
  display: block;
}

/* Show the main SLU banner image on the PV home page only */
#pagePublicHome #header2Container #header2,
#pagePublicHome #header2Container #header2 #bannerImage {
  display: block;
}

/* Hide the main SLU banner image and sub-banner image on
   every other PV page that is not the home page */
#header2Container #header2,
#header2Container #header2 #bannerImage,
#header2Container #header2 .slu_workforce-center-logo {
  display: none;
}

/* Media query styles should be further subcategorized into SMACSS style organization*/
/* Extra small devices, up to 767px and BASE css (mobile view sets the base)*/

@media (max-width: 767px) {

  .infographcontainer {
    display: none;
  }

  .displaynone {
    display: none;
  }

  .teamimage {
    width: 100%;
    height: 100%;
  }

  .clear {
    clear: both;
  }

  #partnerMain {
    height: 65%;
  }

  #partnerMenu {
    width: 100%;
    height: 300px;
  }

  #partnershipBenefits {
    width: 100%;
  }

  #certificateColumn {
    width: 100%;
    border-right: none;
    border-top: 1px solid;
    border-bottom: 1px solid;
    height: 60%;
  }

  #mobileColumn {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid;
    height: 60%;
  }

  #trainingColumn {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid;
    height: 70%;
  }

  /* For smaller devices, hide the sub-banner image on the home page */
  #pagePublicHome #header2Container #header2 .slu_workforce-center-logo {
    display: none;
  }

  /* For smaller devices, show the sub-banner image on
     every other PV page that is not the home page (overrides prev style
     that hides it, only for smaller devices). Also show the main banner image
     on the PV home page for smaller devices */
  #pagePublicHome #header2Container #header2,
  #header2Container #header2,
  #header2Container #header2 .slu_workforce-center-logo {
    display: block;
  }
}

/* Small devices and larger (tablets, 768px and up) */
@media (min-width: 768px) {

  #header1ContentWrapper {
    margin: 0 -15px;
    display: block !important;
  }
  .dropdown:hover .dropdown-menu {
    display: block;
  }
  .teamimage {
    width: 100%;
    height: 100%;
  }
  /* Only show the workforce center contact info and center header logo for Small devices and larger,
     otherwise, it is hidden (default display is set to 'none') */
  #workforceCenterContactWrapper, #workforceCenterLogoWrapper {
    display: block;
  }
}

 #courseCategory {
   display: none;
 }
 label[for=courseCategory] {
   display: none;
 }
 #term {
   display: none;
 }
 label[for=term] {
   display: none;
 }
 #forgotUserNameLink {
   display: none;
 }
 /* This bit of code supercedes anything being done within the advanced search form.
    Starts here. */

 #courseSearchInstructor {
   display: none !important;
 }
 #instructor {
   display: none !important;
 }
 label[for=instructor] {
   display: none !important;
 }
 #courseSearchStartDay {
   display: none !important;
 }
 #dayOfWeek {
   display: none !important;
 }
 label[for=dayOfWeek] {
   display: none !important;
 }
 /* This bit of code supercedes anything being done within the advanced search form.
    Ends here. */


/* This bit of code supercedes anything being done within the my profile info.
    Starts here. */
#studentInfoOtherNamesOnRecord {
   display: none !important;
}
#studentInfoNameToAppearInPrint {
   display: none !important;
}
#studentInfoDateOfBirthContainer {
   display: none !important;
}
option[value=Other] {
   display: none;
}
option[value=Billing] {
   display: none;
}
option[value=Purchaser] {
   display: none;
}
option[value=Foreign] {
   display: none;
}
option[value=Contact] {
   display: none;
}
option[value=Agency] {
   display: none;
}
option[value=Summer] {
   display: none;
}
option[value=School] {
   display: none;
}
option[value=Campus] {
   display: none;
}
option[value=Temporary] {
   display: none;
}
option[value=Local] {
   display: none;
}
option[value=Permanent] {
   display: none;
}
 #addressForeignStateWrapper {
   display: none !important;
 }
 #studentProfileEmergencyContactInfoContainer {
   display: none !important;
 }

 #learningGoals {
   display: none !important;
 }
 #enrollmentTimeframes {
   display: none !important;
 }
/* This bit of code supercedes anything being done within the my profile info.
    Ends here. */

/* Preload images */
body:after {
  content: url(https://slusv.destinysolutions.com/srs/upload/close.png) url(https://slusv.destinysolutions.com/srs/upload/loading.gif) url(https://slusv.destinysolutions.com/srs/upload/prev.png) url(https://slusv.destinysolutions.com/srs/upload/next.png);
  display: none;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  background-color: white;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-container {
  padding: 4px;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(https://slusv.destinysolutions.com/srs/upload/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(https://slusv.destinysolutions.com/srs/upload/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(https://slusv.destinysolutions.com/srs/upload/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(https://slusv.destinysolutions.com/srs/upload/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

span.minMaxUnits {
  visibility: hidden;
}

span.creditType {
  visibility: hidden;
}

.footerLogo {
  margin: 10px;
  float: left;
}

.card-header.panelHeadingToggle {
  background: #c8c9c7;
}

.card-header.panelHeadingToggle.collapsed {
  background: #fafafa;
}

label[for="accreditingAssociations"] {
  visibility: hidden;
}

label[for="accreditingAssociations"]:before {
  content: 'College Credit:' ;
  visibility: visible;
}

.alert.alert-danger {
  background-color: #002f87;
  border-color: #003DA5;
  a{
    color:#ffffff;
    border-bottom:1px solid #ffffff;
  }
}

.alert.alert-warning {
  background-color: #c8c9c7;
  border-color: #003DA5;
  border-width: medium;
  text-align: center;
}


#scholarship {
    background-color: #dddddd;
    height: 120px;
    width: 200px;
    border: 1px solid black;
    float: left;
    border-radius: 10px;
    padding: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

#scholarshipContainer {
   width:100%;
   margin-left: auto;
   margin-right: auto;
}

#menuLogo {
   float: right;
   margin-left: 5px;
   margin-right: 5px;
   margin-bottom: 5px;
}

#errorPanel {
  background: #002f87;
  border: none !important;
}

#errorMessage {
  background: #002f87;
  border: none !important;

}

#errorMessage a {
  color: white;
}

#buttonSignUp {
  color: black;
  width: 80px;
  height: 39px;
}

#buttonViewInstructors {
  display: none;
}

#studentProfilePrivacyPolicyQuestionContainer {
  display: none;
}

#nav7 + .dropdown-menu {
  min-width: 248px;
}

#nav8 {
  min-width: 131px;
}

#nav8 + .dropdown-menu {
  min-width: 100%;
  max-width: 100%;
}

#nav9 + .dropdown-menu {
  min-width: 100%;
  max-width: 100%;
  font-size: .8rem;
  background-clip: inherit;
  border-radius: 0;
}

#nav10 + .dropdown-menu {
  min-width: 100%;
}

#nav11 + .dropdown-menu {
  min-width: 100%;
  max-width: 100%;
  font-size: .8rem;
  background-clip: inherit;
  border-radius: 0;
}

.dropdown-menu li a {
  padding: 5px;
}

span.badge.label-success {
  background-color: #5bc2e7;
  float: left;
  margin-left: 2px;
  margin-top: 2px;
  margin-right: 2px;

}

#registrationButton {
  float: left;
}

#footerStyle2 {
  color: #ffffff;
}

// @media (min-width: 992px)
// .col-md-4 {
//     width: 25%;
// }

#quickJoinEmail.form-control {
  width: 200px !important;
}

.social {
  overflow: auto;

}

#socialMedia {
  padding-top: 8px;
}

/* logo / banner */


#header2 { padding: 0px; }
#workforceCenterContactWrapper {
  position: relative;
  z-index: 1;
}
#workforceCenterContactWrapper:before {
  content: ' ';
  background-color: #003DA5;
  height: 45px;
  position: absolute;
  top: 0;
  left: -4999px;
  width: 9999px;
  z-index: -1;
}

/* main navigation */

h1, h2, h3 {
  font-family: 'Open Sans Narrow', sans-serif;
  color: #003DA5;
  margin-top: 10px;
}
h4, h5, h6 {
  font-family: 'Open Sans', sans-serif;
  color: #003DA5;
}

.btn-primary {
  background-color: #5bc2e7;
  border-color: #5bc2e7;
  font-size: .88rem !important;
}

.btn-primary:visited, .btn-primary:focus, .btn-primary:active {
  background-color: #5bc2e7;
  border-color: #5bc2e7;
}

.btn-primary:hover {
  background-color: #4781AC;
  border-color: #2D6792;
}

/* forms */
.form-control {
  font-size: 14px;
  line-height: 1.3;
  color: #444;
  background-color: #efefef;
  width: 200px;

}

#studentProfileAddresGroupPanel #addressStateSR:after {
                content: ":";
}

#studentProfileAddresGroupPanel #addressStateWrapper label:after {
                content: "\2a";
                color: #B5231D;
                font-family: 'Glyphicons Halflings';
                font-style: normal;
                font-weight: normal;
                font-size: 8px;
                vertical-align: 5px;
}

/* CAP student profile - State/Province */
#memberProfileAddressInfoContainer #addressStateSR:after {
                content: ":";
}
#memberProfileAddressInfoContainer #addressStateWrapper label:after {
                content: "\2a";
                color: #B5231D;
                font-family: 'Glyphicons Halflings';
                font-style: normal;
                font-weight: normal;
                font-size: 8px;
                vertical-align: 5px;

}

.card-info {
  border-color: #003da5;
}

.sectionHeaderSemesterContainer {
  color: #003da5;
}

.courseSectionDelivery {
  color: #003da5;
}

.card-info>.card-header {
  border-color: #003da5;
}

.card-info>.card-header+.panel-collapse>.card-body {
  border-top-color: #003da5;
}

.alert-danger hr {
  border-top-color: black;
}

.pagination>.active>a, .pagination>.active>a:focus,
.pagination>.active>a:hover {
  background-color: #003da5;
}

.schedule .btn {
  display: block;
}

.btn-new, .btn-new:visited, .btn-new:focus, .btn-new:active {
  color: #ffffff;
  background-color: #003b5c;
    border-color: #003b5c;
    font-size: .88rem !important;
}

.btn-new:hover {
  color: #ffffff;
  background-color: #3e657b;
  border-color: #3e657b;
}

#labelcontentBlockPG0020 {
  visibility: hidden;
}

#labelcontentBlockPG0020:before {
  content: "Continuing Education Unit";
  visibility: visible;
  }

.cyber:visited {
  background-color: #5bc2e7;
  border-color: #5bc2e7;
}

.btn-warning, .btn-warning:visited, .btn-warning:focus, .btn-warning:active {
  background-color: #e99002;
    border-color: #d08002;
    font-size: .88rem !important;
    padding: .375rem .25rem !important;
  color: white;
}

.btn-warning:hover {
background-color:#b67102;
border-color:#935b01;
}

.btn-success, .btn-success:visited, .btn-success:focus, .btn-success:active {
  background-color: #43ac6a;
  border-color: #3c9a5f;
  font-size: .88rem !important;
  padding: .375rem .25rem !important;
}

.btn-success:hover {
  background-color: #358753;
  border-color: #2b6e44;
}

.btn-online, .btn-online:visited, .btn-online:focus, .btn-online:active {
  color: #ffffff;
  background-color: #ffc72c;
  border-color: #ffc72c;
  font-size: .88rem !important;
  padding: .375rem .25rem !important;
}

.btn-online:hover {
  color: #ffffff;
  background-color: #d8ae39;
}

option[value=Cheque] {display: none;}
option[value=BankWire] {display: none;}

.itil {
  font-size: xx-large;
  color: black;
text-decoration: none;
}
#pageCourseProfile #pageHeader .courseCode {
  font-size: 18px;
  display: block;
}
#pageCourseProfile .nameAndCodeSeparator {
  display: none;
}
#pageCourseProfile #pageHeaderActions {
  margin-top: 0px;
}
#pageCourseProfile #pageHeader {
  margin-top: 20px;
}
#pageCourseProfile #courseProfileInstructionMethods {
  display: block;
}
.alert-danger {
  color: #ffffff;
}
.certtag {
  margin-top: -15px !important;
  padding: 10px 0;
  text-align: center;
}
.certtag p {
  position: relative;
  display: inline-block;
  margin: 0;
  font: italic 600 30px/1 "Crimson Text", serif;
  text-shadow: 1px 1px 2px rgba(0,0,0,1);
}
.certtag p::before,
.certtag p::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 70px;
  height: 2px;
  background-color: #fff;
}
.certtag p::before { left: -140px; }
.certtag p::after { right: -140px; }

.corporaterow {
  padding: 15px;
  display: flex;
  justify-content: center;
}

.marg {
  margin-right: -15px;
  margin-left: -15px;
}

.flexColumn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  flex-direction: column;
}

.flexRow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
  flex-wrap: wrap;
}

.company-panel {
  height: 100%;
}

/****************************
 * HEADER STYLES
 ****************************/
#header1 {
  padding-top: 0;
  height: auto;
}
.headerLogo img { max-width: 100%; }
.loginInfo { margin-left: 0; }
.headerContactInfo p,
.quickSearch,
.cartInfo,
.loginInfo {
  font-family: "Brandon Grotesque", sans-serif;
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  .quickSearch,
  .cartInfo,
  .loginInfo {
    float: right;
    margin: 20px 0 13px 20px;
  }
}



/****************************
 * TAGLINE STYLES
 ****************************/
.tagline {
  margin-top: -15px !important;
  padding: 20px 0;
  text-align: center;
}
.tagline p {
  position: relative;
  display: inline-block;
  margin: 0;
  font: italic 600 30px/1 "Crimson Text", serif;
  text-shadow: 1px 1px 2px rgba(0,0,0,1);
}
.tagline p::before,
.tagline p::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 70px;
  height: 2px;
  background-color: #fff;
}
.tagline p::before { left: -140px; }
.tagline p::after { right: -140px; }


/****************************
  * BORDERED CONTAINER STYLES
  ****************************/
.bordered-container {
  margin: 10px 0 0;
  border: 13px double white;
  border-radius: 13px;
  padding: 25px;
}
.bordered-container h1 {
    font: bold 30px/1.3 "Brandon Grotesque", sans-serif;
    text-transform: uppercase;
    color: white;
}
.bordered-container p {
    color: white;
}
@media screen and (min-width: 768px) {
    .bordered-container {
        margin: 20px 0 0;
    }
}

video {
    display: block;
    width: 100%;
}
.video-container {
    border-radius: 10px;
    overflow: hidden;
}
@media screen and (max-width: 767px) {
    .bordered-container{ 
        margin-left: 15px;
        margin-right: 15px;    
    }
    .video-container { margin-top: 20px;} 
}



 /****************************
  * CTA BOX STYLES
  ****************************/
.cta-boxes {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
  margin-top: 30px;
}
.cta-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 15px;
  padding: 15px;
  border-radius: 13px;
  border: 13px double white;
}
.cta-box.half,
.cta-box.one-third {
  width: 100%;
}
.cta-box__title,
.cta-box__subtitle {
  margin: 0;
  text-shadow: 0.075em 0.075em rgba(0,0,0,0.5);
}
.cta-box__title {
  margin-bottom: 0;
  font: bold 21px/1.3 "Brandon Grotesque", sans-serif;
  text-transform: uppercase;
}
.cta-btn {
  display: inline-block;
  margin-top: 10px;
  color: #fff;
  border: 1px solid #fff;
  padding: 7px 18px;
  border-radius: 10px;
  font-size: 16px;
  transition: .3s all ease-in-out;
  background: rgba(0,0,0,0.1);
}
.cta-btn:hover {
  background: rgba(0,0,0,0.3);
  text-decoration: none;
  color: #fff;
}
.cta-btn--reverse {
    background: white;
    color: black;
}
.cta-btn--reverse:hover {
    background: rgba(255,255,255,0.7);
    text-decoration: none;
    color: black;
}
.cta-btn--reverse:focus {
    color: black;
}

@media screen and (min-width: 768px) {
  .cta-box.half {
    width: calc(50% - 15px);
    min-height: 180px;
  }
  .cta-box.one-third {
    width: calc(33.3333% - 15px);
  }
  .cta-box {
    margin: 0 0 30px;
    padding: 20px;
  }
  .cta-box.half .cta-box__title { font-size: 30px; }
  .cta-box.half .cta-btn { font-size: 20px; }
}


/****************************
 * VERTICAL CAROUSEL STYLES
 ****************************/
.ticker-headline {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 15px 0;
    margin: 0;
    font-size: 30px;
    font-family: "Brandon Grotesque", sans-serif;
  }
  .ticker-headline a {
    color: black;
  }
  
  .carousel.vertical {
      overflow: auto;
    margin: 0 0 50px;
    border-top: 5px solid #ffd96e;
    border-bottom: 5px solid #ffd96e;
    background: repeating-linear-gradient(-45deg, transparent 0, transparent 14px, #fafafa 14px, #fafafa 22px);
  }
  .carousel.vertical .carousel-label {
      display: block;
      float: left;
      padding: 20px;
      font-size: 24px;
      font-family: "Brandon Grotesque", sans-serif;
  }
  @media screen and (max-width: 767px) {
    .ticker-headline {
        padding: 2px 0 15px 15px;
        font-size: 20px;
    }
    .carousel.vertical .carousel-label {
        float: none;
        padding: 15px 15px 0;
        font-size: 20px;
    }
  }
  .carousel.vertical .carousel-inner {
    height: 100%;
    width: auto;
  }
  .carousel.vertical .carousel-inner > .item {
    width: auto;
    padding-right: 50px;
    transition: 0.6s ease-in-out top;
  }
  @media all and (transform-3d), (-webkit-transform-3d) {
    .carousel.vertical .carousel-inner > .item {
      transition: 0.6s ease-in-out;
    }
    .carousel.vertical .carousel-inner > .item.next, .carousel.vertical .carousel-inner > .item.active.right {
      -webkit-transform: translate3d(0, 100%, 0);
              transform: translate3d(0, 100%, 0);
      top: 0;
    }
    .carousel.vertical .carousel-inner > .item.prev, .carousel.vertical .carousel-inner > .item.active.left {
      -webkit-transform: translate3d(0, -100%, 0);
              transform: translate3d(0, -100%, 0);
      top: 0;
    }
    .carousel.vertical .carousel-inner > .item.next.left, .carousel.vertical .carousel-inner > .item.prev.right, .carousel.vertical .carousel-inner > .item.active {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      top: 0;
    }
  }
  .carousel.vertical .carousel-inner > .active,
  .carousel.vertical .carousel-inner > .next,
  .carousel.vertical .carousel-inner > .prev {
    display: block;
  }
  .carousel.vertical .carousel-inner > .active {
    top: 0;
  }
  .carousel.vertical .carousel-inner > .next,
  .carousel.vertical .carousel-inner > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .carousel.vertical .carousel-inner > .next {
    top: 100%;
  }
  .carousel.vertical .carousel-inner > .prev {
    top: -100%;
  }
  .carousel.vertical .carousel-inner > .next.left,
  .carousel.vertical .carousel-inner > .prev.right {
    top: 0;
  }
  .carousel.vertical .carousel-inner > .active.left {
    top: -100%;
  }
  .carousel.vertical .carousel-inner > .active.right {
    top: 100%;
  }
  
  .carousel.vertical .carousel-control {
    left: auto;
    width: 50px;
    color: black;
  }
  .carousel.vertical .glyphicon {
    color: black;
  }
  .carousel.vertical .carousel-control.up {
    top: 0;
    right: 0;
    bottom: 40%;
  }
  .carousel.vertical .carousel-control.down {
    top: 40%;
    right: 0;
    bottom: 0;
  }
  .carousel.vertical .carousel-control .icon-prev,
  .carousel.vertical .carousel-control .icon-next,
  .carousel.vertical .carousel-control .glyphicon-chevron-up,
  .carousel.vertical .carousel-control .glyphicon-chevron-down {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
  }
  .carousel.vertical .carousel-control .icon-prev,
  .carousel.vertical .carousel-control .glyphicon-chevron-up {
    left: 50%;
    margin-left: -10px;
    top: 50%;
    margin-top: -10px;
  }
  .carousel.vertical .carousel-control .icon-next,
  .carousel.vertical .carousel-control .glyphicon-chevron-down {
    left: 50%;
    margin-left: -10px;
    top: 50%;
    margin-top: -10px;
  }
  .carousel.vertical .carousel-control .icon-up,
  .carousel.vertical .carousel-control .icon-down {
    width: 20px;
    height: 20px;
    line-height: 1;
    font-family: serif;
  }
  .carousel.vertical .carousel-control .icon-prev:before {
    content: '\2039';
  }
  .carousel.vertical .carousel-control .icon-next:before {
    content: '\203a';
  }

@media screen and (max-width: 767px) {
    #contentModule5 { margin: 0 15px; }
}

/****************************
 * UTILITY STYLES
 ****************************/
.bg-slu-blue { background-color: #003da5; color: #fff; }
.bg-college-church-grey { background-color: #c8c9c7; color: #fff; }
.bg-fountain-blue { background-color: #5bc2e7; color: #fff; }
.bg-grand-blue { background-color: #003b5c; color: #fff; }
.bg-billiken-bronze { background-color: #795d3e; color: #fff; }
.bg-gateway-gold { background-color: #ffc72c; color: #fff; }
.bg-rooftop-teal { background-color: #8fd6BD; color: #fff; }
.bg-oriflamme-orange { background-color: #ed8b00; color: #fff; }
.bg-dark-gray { background-color: #333; color: #fff; }
.bg-image-overlay {
  position: relative;
  z-index: 1;
}
.bg-image-overlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .4;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
}
.bg-patti-teaching::after {
  background-image: url(../../../images/bg_patti-teaching.jpg);
  background-position: center top;
}
.bg-students1::after {
  background-image: url(../../../images/bg_students1.jpg);
  background-position: center top;
}
.bg-ethernet::after {
  background-image: url(../../../images/bg_ethernet.jpg);
  background-position: center top;
}
.bg-pattern {
  background-image: url(../../../images/pattern.png);
  background-position: center top;
  background-repeat: repeat-x;
}
@media screen and (min-width: 768px) {
  .container-full-width {
    margin-left: 50%;
    transform: translateX(-50vw);
    width: 100vw;
  }
}

/* TODO: Remove this, do it the proper way */
#header2Wrapper,
#workforceCenterContactWrapper { display: none; }
.newsItemSummary p { font-size: 14px; }


/* Partner Logos */
.partner-logos {
  display: none;
  background: #e3e3e3;
  padding: 15px 0;
  text-align: center;
}
#pagePublicHome .partner-logos { display: block; }
.partner-logos ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
     -ms-flex-pack: center;
   justify-content: center;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.partner-logos li {
  padding: 10px 35px;
}
.partner-logos li:first-child { margin-left: 0;}

/* Printing Course Profile */
.printingLogo {
  display: none;
}

@media print {
  .printingLogo {
    display: block;
    visibility: visible;
  }

  .headerLogo {
    display: none;
  }

  #quickCourseSearch {
    display: none;
  }

  #logoInfo {
    display: none;
  }

  #cartInfo {
    display: none;
  }
}

/* Picture in Program Stream */
#programStreamCourses {
  clear: both;
}

.streamPicture {
  float: right;
  margin-bottom: 10px;
  margin-right: 230px;
  margin-top: 20px;
}

@media screen and (max-width: 992px) {
  .streamPicture {
    margin-left: 20px;
    margin-top: 5px;
    float: none;
  }
}

.staff {
  background-color: #ccc;
}


/*PM Summit Page */

.timeEvent {
  text-align: right;
  font-weight: bold;
  font-size: 18px;
}

@media screen and (max-width: 767px) {
  .timeEvent {
  text-align: left;
  font-weight: bold;
  font-size: 18px; }
}


/*News and Events */

.news {
  border: 2px solid;
}

/*VA Page */

.va-container {
  display: table;
  width: 100%

}

.va-row {
  display: table-row;
  width: 100%;
}

.va-col {
  display: table-cell;
  width: 33%;
  padding: 10px; 

}

.va-1 {
padding: 10px;
  background-color: #7eceea; 
  color: #003b5c;
  height: 460px;
  border-radius: 25px;
  font-size: 14px;

}

.va-2 {
  padding: 10px;
  background-color: #fed166; 
  color: #003b5c; 
  height: 460px;
  border-radius: 25px;

}

.va-3 {
  padding: 10px;
  background-color: #eba54f; 
  color: #003b5c; 
  height: 460px;
  border-radius: 25px;

}

@media only screen and (max-width: 990px) {
    .va-col { 
        display: block;
        width: 100%;
    }

    .va-1 {
      height: 100%

    }

    .va-2 {
      height: 100%;

    }

    .va-3{
      height: 100%;

    }

}
.bg-image-overlay2::after {
  border-radius: 25px;

}

.certtag2 {
  background-image: url(https://workforcecenter.slu.edu/upload/TWA_banner.jpg);
  background-repeat: no-repeat;
  background-position: center;
}

span.amountCol {
	display: none;
}

/*Shopping Cart*/

.shoppingCartLabel {
  font-size: 18px;
}

.cartItemFeeHeaderCheckbox {
  font-size: 18px;
  color: black;
}

div.feeInfoGroupDiv > div.cartItemOptions.cartItemFeeHeader {
  color: black;
}

div.cartItemFeeGroup.cartItemFeeGroupTuitionProfile > div.panelSection > div.panelRow.cartItemOptions > div.noInputControl.feeDescriptionContainer > span.noColonLabel > span.cartItemOptionDescription {
  display:none;
}

#cartCount {
  position: unset;
  display: inline-block;
}

/*Certificate Page*/

#certificateNumRequired {
  display: none;
}

/*About Us*/

.carousel-item img{
  width: 100%;
}

.carousel-indicators li{
  width: 10px;
  height: 10px;
  border-radius: 50%;
} 