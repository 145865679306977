.courses{
    margin: 40px 0;
    a{
        text-decoration: none;;
    }
    h2.title{
        text-transform: uppercase;
        text-align: center;
        font-weight: 500;
        font-size: 36px;
        letter-spacing: 2px;
        margin: 0 0 5px;
        img{
            width: 72px;
            top: 0px;
            position: relative;
        }
    } 
    .banner-solid-container{
        display: flex;
        justify-content: space-between;
        margin: 20px 0 50px;
        @media screen and (max-width:$break){
            flex-wrap:wrap;
        }        
        .banner-solid {
            color:#ffffff;
            width: 18%;
            text-align: center;
            background-color:$primary-color;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;      
            margin:0;
            @media screen and (max-width:$break){
                width: 48%;
                margin-bottom:12px;
            }  
            @media screen and (max-width:$handheld){
                width: 100%;
            }                        
            a{
                font-family:$display;
                text-transform:uppercase;
                color:#ffffff;
                letter-spacing: 2px;  
                line-height: 20px;
                font-size: 18px;
                padding: 12px 5px;
                width:100%;
            }
            &:hover{
                background:$secondary-color;
                a {
                    color:$primary-color;
                }
            } 
        }        
    }
    .banner-line-container{
        display: flex;
        justify-content: space-between;
        margin: 0 0 50px;
        @media screen and (max-width:$tablet){
            flex-direction:column;
        }
        //banner-line change to "bannner shape"
        .banner-line{
            color:#ffffff;
            width: 30%;
            text-align: center;
            background-image:url('https://workforcecenter.slu.edu/images/blue-banner.png');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;            
            margin:0;
            @media screen and (max-width:$tablet){
                width: 100%;
                margin:20px 0;
            }
            a{
                font-family:$display;
                text-transform:uppercase;
                font-size: 22px;
                color:#ffffff;
                display: block;
                padding: 10px 0;                
            }
        }
    }
    .courses-info-container{
        display: flex;
        margin: 30px 0 0;
        @media screen and (max-width:$tablet){
            flex-direction: column;
        }
        .courses-info{
            width: 66.66%;
            display: flex;
            flex-direction: column;
            background-color: $offwhite;
            padding:20px;
            @media screen and (max-width:$tablet){
                width:100%;
                margin-bottom: 30px;
            }
            h2{
                a{
                    color:$primary-color;
                }           
            }
            .block-grid{
                @media screen and (max-width:$tablet){
                    flex-direction: column;
                }
            }
            .block{
                @media screen and (max-width:$tablet){
                    width:100%;
                }
                h3{
                    font-size: 18px;
                    color:$primary-color;
                    font-weight: 600;
                }
                span{
                    color:$primary-color;
                    font-weight: bold;
                }
                ul {
                    @media screen and (max-width:$tablet){
                        padding:0;
                    }
                    li{
                        line-height: 24px;
                        padding-bottom: 7px;
                    }
                }
            }
        }
    }
}