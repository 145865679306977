.article-banner{
    padding: 20px 0;
    .wrapper{
        display: flex;
        position: relative;
        @media screen and (max-width:$break){
            flex-direction:column;
        }
    }
    .sidebar{
        width: 30%;
        border:3px solid $secondary-color;
        text-align: center;
        max-width: 300px;
        position: absolute;
        right: 0;
        font-size: 24px;
        background-color:#ffffff;
        @include box-shadow(10px, 10px, 20px, rgba(0,0,0,.2)); 
        @media screen and (max-width:$break){
            width: 100%;
            position:relative;
            margin:30px 0;
            max-width: none !important;
        }
        h2{
            text-align: center;
            color: #ffffff;
            margin: 0;
            background-color: #ffc72c;
            span{
                background-image: url('https://workforcecenter.slu.edu/images/border-sidebar.png');
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                width: 270px;
                display: block;
                text-transform: uppercase;
                font-style: italic;
                padding: 28px 0;
                font-size: 24px;
                line-height: 24px;        
                margin: 0 auto;        
            }
        }
        img {
            width: 149px;    
            margin: 15px 0;
     
        }
        .orange-container{
            background-color:$secondary-color;
            padding: 4px 4px 0px;
            a.orange {
                display: block;
                color:#ffffff;
                background-color: #ed8b00;
                padding: 7px 0;
                text-decoration: none;
                @include border-radius(14px);
            }            
        }
        h3{
            font-family:$display;
            font-weight:bold;
            margin: 0;
            text-transform:uppercase;
            color:#ffffff;
            font-size: 18px;
        }
        &.images{
            position: relative;
            img {
                width: 90%;    
                margin: 15px auto;
                display: block;
            }
        }
        &.image { //singaular image
            position: relative;
            margin-bottom: 4px;
            img{
                width: 100%;
                margin:0;
            }
        }
    }
    &.sidebar {
        .wrapper{
            justify-content: space-around;
        }
        .main{ 
            width: 65%;
            @media screen and (max-width:$break){
                width:100%;
            }
        }
    }
    &.sidebar-absolute {
        .main{ 
            width: 65%;
            @media screen and (max-width:$break){
                width:100%;
            }
        }        
    }
    &.center {
        padding: 80px 0;
        .main{
            text-align:center;
        }
    }
    &.extra-space{
        padding:50px 0;
    }
}