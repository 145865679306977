///Break Points
$grid: 1400px;
$wide: 1200px;
$break: 991px;
$wrappernarrow:850px;
$tablet: 768px;
$handheld: 600px;
$mobile: 400px;
$iphone5: 320px;

///Fonts
$body-font: Arial, Helvetica, sans-serif;
$display: brandon-grotesque, sans-serif;

///Colors
$primary-color:#003da5;
$secondary-color:#ffc72c; 

$fountain-blue: #53c3ee;
$dark-gray:#636466;
$mid-gray:#7C7C7C;
$light-gray:#F6F6F6;


$offwhite: #F1F1EF;

///Animation 
$duration-short:200ms;
$duration:500ms;
$duration-long:2500ms;

$prod:true;
$url:'../';
@if $prod == true {
    $url:'https://workforcecenter.slu.edu/';
}