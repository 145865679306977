ul.checkmarks{
    li{
        &:before {
            content:'';
            background-image:url($url + 'images/icon-checkmark.svg');
            background-position: center;
            background-repeat: no-repeat;
            width: 20px;
            height:20px;
            display: block;
            position: absolute;
            left:-30px;
            top:-1px;
        }
    }
}