.wrapper {
    max-width:1200px;
    margin:0 auto;
    padding:0 30px;
    width: 100%;
    .wrapper{
        padding:0 0px;
    }
}

.thin-wrapper{
    max-width:900px; 
    margin:0 auto;
    padding:0 30px;
    width: 100%;
}

.extra-thin-wrapper{
    max-width:560px; 
    width: 100%;
    margin:0 auto;
    padding:0 30px;
}