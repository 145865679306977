@keyframes background-zoom {
    from {
        background-size: 160%;
    }
    to {
        background-size: 100%;
    }
  }

.banner{
    background-position: center;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 25%;
    margin-bottom: -8px;
    @media screen and (max-width:$handheld){
        padding-bottom: 0;
        height: 280px;
    }
    span{
        display: block;
        height: 250px;
        padding-top: 10px;    
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,.9) 0%, rgba(255,255,255,0) 100%);
        @media screen and (max-width:$tablet){
            height: 200px;    
            background: linear-gradient(180deg, rgba(255,255,255,.8) 75%, rgba(255,255,255,0) 100%);
        }
    }   
    h1{
        font-weight: 600;
        letter-spacing: .1em;
    }
    h2{
        font-size: 2.3em;
        letter-spacing: .1em;
    }    
    &.text-bottom{
        padding-top: 21%;
        padding-bottom: 2%;
    }
    &.text--white{
        h1,h2{
            color:#ffffff;
            text-transform: uppercase;
        }
    }
    &.animate {
        animation-name: background-zoom;
        animation-duration: 40s;
        animation-timing-function: ease-out;
        padding-bottom: 10%;
    }
}