.btn-1{
    background-color:$secondary-color;
    color:$primary-color;
    padding:8px 12px;
    font-family:$display;
    font-weight: bold;
    text-transform: uppercase;
    @media screen and (max-width:$tablet){
        display: block;
    }
}

.btn-2{
    background-color:$primary-color;
    color:$fountain-blue;
    padding:8px 12px;
    font-family:$display;
    font-weight: bold;
    text-transform: uppercase;
    @media screen and (max-width:$tablet){
        display: block;
    }
}

.btn-wrapper{
    text-align: center;
    display: block;
    &.align-left{
        text-align: left;
        margin: 0 0 30px;
    }
    &.spacing{
        margin: 10px 0 20px;
    }
    &.extra-spacing{
        margin: 30px 0;
    }
}