::-moz-selection { 
  color: #ffffff;
  background-color:$secondary-color;
}
::selection {
  color: #ffffff; 
  background-color:$secondary-color;
}

html {
  overflow-x: hidden;
}

a{
  color:$primary-color;
}

img{
  max-width:100%;
}

.public-annoucement{
  > h2,
  > h2 + .newsItemHeader,
  > h2 + .newsItemHeader + .newsItemSummary {
    display: none;
  }
  .newsItemSummary{
    > h2{
      display: none;
    }
  }
}

#pageDirectionsMain #mainContent{
  padding:30px 0 0;
}

.article-testimonial{
  padding:50px 0;
  .testimonial{
    padding-top: 0;
  }
}

pre{
    background-color: $primary-color;
    color:#ffffff;
    font-family: 'Courier New';
    padding:6px 10px;
}

#myModal {
    background: rgba(0,0,0,.5);
    svg{
        display: block;
        height: 40px;
        fill: #ffffff;
        margin: 0 0 12px;
    }
    .modal-content{
        border: 0;
        background-color:transparent;
        .close{
            color: #fff;
            opacity: 1;
            font-size: 65px;
        }
    }
    .modal-dialog {
        @media (min-width: 576px){
            max-width: 80%;
        }
    }    
}

.admin-red{
  background-color: red;
  color: #ffffff;
  padding: 10px;
  font-weight: bold;
}