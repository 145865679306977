.icons{
    background-color:$offwhite;
    text-align:center;
    padding:0 0 20px;
    display: flow-root;
    &.background-white{
        background-color: #ffffff;
    }
    h2{
        color:$primary-color;
        padding: 20px 0;
        font-size: 40px;
        font-style: italic;        
    }
    .icon-item-container{
        display: flex;
        justify-content: space-between;
        @media screen and (max-width:$tablet - 1){
            flex-direction: column;
        }
        a{
            display: block;
        }
        .icon-item{
            text-align:center;
            flex-grow: 1;
            flex-basis: 0;
            padding: 20px 40px;
            @media screen and (max-width:$tablet){
                margin:10px 0;
            }
            .center{
                align-content: center;
            }
            .icon-img{
                background-color:#ffffff;
                @include border-radius(10px); 
                padding:25px;
                width: 180px;
                height: 180px;
                position: relative;
                margin:0 auto;
                img{
                    width: 130px;
                    position: absolute;
                    margin: auto;
                    right: 0;
                    left: 0;
                }            
            }
            h3{
                color:$primary-color;
                text-transform:uppercase;
                font-weight:bold;
                padding:10px 0;
                font-family: $display;   
            }
            p{
                font-family: Arial, Helvetica, sans-serif;  
            }              
        }        
    }
}