#hero-full-width{
    .grid {
        padding:25px 0;
        display:flex;
        justify-content: space-between;
        @media screen and (max-width:$tablet){
            flex-direction: column;
        }    
        .grid{
            padding:0;
        }
        h2{
            padding-bottom: 3px;
            margin: 0 0 10px;        
        }
        .list-inner-container{
            height: 330px;
        }
        &.grid-three-column-two-columns > .column{
            width:33%;
            &:first-of-type{
                width:64%;
            }
            @media screen and (max-width:$tablet){
                width:100%;
                margin:10px 0;
            }
            img{
                max-width: 150px;
                clear: both;
                display: block;
                padding: 15px 0;
            }
        }  
        &.grid-three-column .column{
            width:31%;
            margin:0;
            @media screen and (max-width:$tablet){
                width:100%;
                margin:10px 0;
            }
        }      
        &.grid-two-column .column{
            width:47.5%;
            margin:0;
            @media screen and (max-width:$tablet){
                width:100%;
                margin:10px 0;
            }
        }          
    }    
}