article {
    .wrapper{
        display: flex;
        position: relative;
        @media screen and (max-width:$break){
            flex-direction:column;
        }
    }
    h1{
        padding-bottom: 10px;;
    }
    h2{
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 28px;
    }
    h3{
        color:$primary-color;
        font-size: 24px;
        font-family: $display;
    }
    h4{
        font-size: 20px;
        font-family: $display;
    }
    h5{
        font-size: 18px;
        font-family: $display;        
    }
    span {
        color:$primary-color;
        font-weight: bold;
    }
    a{
        color:#000;
        font-weight:bold;
        text-decoration:none;
    }
    .icon-img{
        padding:20px 0;
        img {
            width: 150px;
        }
    }
    .break{
        height: 60px;
    }    
    .logo {
        display: block;
        max-width: 400px;
        margin: 0 auto;
        @media screen and (max-width:$handheld){
            max-width: 100%;
        }
    }
    .title {
        text-align: center;
        padding-bottom: 20px;
        h1{
            padding-bottom: 0;
        }
        h4{
            margin-bottom: 3px;
        }
    }
    .coupon-code {
        text-align: center;
        background-color: $light-gray;
        padding: 15px 30px;
        div{
            display: block;
        }
        span {
            background-color: $primary-color;
            color:$secondary-color;
            padding:8px 12px;
            text-align: center;
            margin:20px auto;
            font-family: $display;
            letter-spacing: 1px;
            font-weight: bold;
        }
        p{
            margin: 20px 0;
        }
    }
    .line-spacer{
        border-top:$light-gray 6px solid;
        width: 100%;
        margin: 20px 0;
    }   
    .video-trigger{
        cursor:pointer;
    }
    .textalign-right{
        padding:25px;
        @media screen and (min-width:$handheld){
            text-align: right;
            max-width: 400px;
            float: right;
            padding: 0 20px 20px;
        }
    }
    ol.numbers,
    ul.checkmarks{
        list-style: none;
        li{
            position: relative;
            line-height: 22px;
            margin: 0 0 15px;
        }
    }
    ol.numbers {
        counter-reset: ol-counter;
        li{
            counter-increment: ol-counter;
            position: relative;
            &:before{
                content: counter(ol-counter);
                background-color: $primary-color;
                width: 25px;
                height: 25px;
                @include border-radius(50%);
                color:#ffffff;
                font-weight: bold;
                font-family: $display;
                text-align: center;
                padding: 2px 0 0;
                position: absolute;
                left: -34px;      
                @include text-shadow(1px, 1px, 1px, #000000);          
            }
        }
    }
    .mobile-table{
        @media screen and (max-width:$tablet){
            width: 100%;
            overflow: scroll;
        }
    }
    table{
        a{
            color: $primary-color;
            font-weight: normal;
            font-weight: 500;
        }
        th,td{
            padding: 5px 8px;
        }
        &.courses{
            width: 100%;
            margin:0;
            border-bottom: 1px solid $mid-gray;
            th,td{
                padding:5px;
                border-right: 1px solid $mid-gray;
                &:first-of-type{
                    border-left: 1px solid $mid-gray;
                }                
            }
            th{
                background-color: $primary-color;
                color:#ffffff;
                vertical-align: top;
            }
            td{
                background-color: $offwhite;
            }
        }        
        &.table-three-column{
            th,td {
                width:33.334%
            }
        }
        &.table-five-column{
            @media screen and (max-width:$handheld) {
                width: 595px;
            }
            th,td {
                width: 20%;
                @media screen and (max-width:$handheld) {
                    width: 119px;
                }                
            }
        }
        &.list{
            tr{
                td{
                    padding: 12px;
                    font-size: 16px;
                    border-bottom: 1px solid $mid-gray;
                    &:first-child{
                        color:$primary-color;
                        background-color: $offwhite;
                        font-weight: 500;
                        font-family: $display;
                        border-right: 1px solid $mid-gray;
                        border-left: 1px solid $mid-gray;
                        font-size: 22px;
                    }
                    +td{
                        background-color:darken($light-gray,8%);
                        border-right: 1px solid $mid-gray;
                        width: 180px;
                        text-align: center;
                        vertical-align: top;   
                        padding-top: 16px;             
                    }
                }
                &:first-child{
                    border-top: 1px solid $mid-gray;
                }
            }
        }
    }    
}