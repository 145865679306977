.list{
    padding: 30px 0;
    &:not(.grid-two-column) h2{
        padding-bottom:30px;
        font-size:42px;
    }
    ul {
        display:inline-block;
        vertical-align:top;
        min-width: 200px;
        a{
            display:flex;
        }
        &.logos-grid{
            min-width: initial;
            padding:0;
            flex-wrap: wrap;
            display:flex;   
            li {
                width: 33.33%;
                list-style: none;
                @include filter(grayscale,100%);
                @include transition(all,$duration);
                opacity:.5;
                padding: 10px 15px;
                @media screen and (max-width:$tablet){
                    width: 50%;
                }
                @media screen and (max-width:$iphone5){
                    width: 100%;
                }
                img{
                    max-width: 200px;
                    margin: 0 auto;
                    @media screen and (max-width:$tablet){
                        max-width: 160px;
                    }
                    @media screen and (max-width:$mobile){
                        max-width: 100%;
                    }                    
                }
                a{
                    display: block;
                }
                &:hover,
                &:active {
                    @include filter(grayscale,0);
                    @include transition(all,$duration);
                    opacity:1;
                }
            } 
        }
    }
    &.center{
        text-align:center;
        max-width: 100%;
    }
}