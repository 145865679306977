.th-login{
    display:none;
    margin: -5px 0 0;
}
.navbar-nav{
    @media screen and (max-width:$tablet - 1){
        padding-top: 30px;
    }
}
.nav.navbar-nav > li,
nav.utility > ul > li {
    &>ul{
        display:none;
        background-color: #fff;
        padding:0;
        @media screen and (min-width:$break){ 
            position: absolute;
            z-index: 5000;
            top:100%;
        }
        li{
            list-style: none;
            a {
                border: 0px solid #fff;
                padding: 4px 10px;
                color:$primary-color;
            }
        } 
    }
    &:hover ul {
        display: block;
        @include box-shadow(10px, 10px, 20px, rgba(0,0,0,.4)); 
        margin:0;
    }    
}
#headerWrapper{
    a{
        text-decoration: none;
    }
    nav.utility{
        z-index: 1000;
        top:3px;
        > ul {
            padding:0;
            margin:0;
            background-color: #ffffff;
            > li{
                display: inline-block;
                margin:0 5px;
                #loginInfo{
                    margin: -6px 0 0 0;
                    padding: 0 !important;
                }
                > a,
                #loginInfo {
                    background-color: $primary-color;
                    color:#ffffff;
                    padding: 5px 17px;
                    text-transform: uppercase;
                    font-family: $display;
                    letter-spacing: 2px;
                    font-size: 16px;      
                    &:hover{
                        background-color: $secondary-color;
                        color: $primary-color;
                    }      
                    #loginNameWrapper{
                        width: auto;
                        max-width: none;
                        padding-left: 15px;                                 
                    }
                    #logoutLinkWrapper{
                        width: 112px;
                        max-width: none;
                        padding-right: 15px;
                    }
                }
            }
        }
        &.top-left{
            position: absolute;
            left:0;
            @media screen and (max-width:$break){
                display:none;
            }
        }
        &.top-right{
            position: absolute;
            right:0;
            @media screen and (max-width:$break){
                left: 0;
                margin: auto;
                width: 195px;
            }
        } 
    }
    #header1Wrapper{
        border-top:3px solid rgba(237,139,0,100);
        margin-top:3px;
        .headerLogo {
            display: block;
            margin: 10px auto;
            width: 400px;
            max-width: 100%;
            @media screen and (max-width:$handheld){
                max-width: 70%;
                width:auto;
            }
            img{
                display: block;
                padding: 30px 0 0px;
                margin: 0 auto;
                width: 100%;
                @media screen and (max-width:$tablet - 1){
                    padding: 5px 0 0px;
                }
            }
        }       
        .headerContactInfo-container{
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;  
            padding-top: 55px;    
            @media screen and (max-width:$tablet - 1){
                padding-top: 0;
            }
            .headerContactInfo{
                text-align: right;
                margin: 0 30px 0 0;
                p,a {
                    color:$primary-color;
                }
                #cartInfo{
                    float: none;
                    text-align: right;
                    margin: 0;
                    .glyphicon-shopping-cart:before {
                        content:'';
                        background-image: url('https://workforcecenter.slu.edu/images/cart.png'); 
                        width: 52px;
                        height: 32px;
                        display: block;
                        background-repeat: no-repeat;
                        background-size: cover;                        
                    }
                    #cartCount{
                        position: initial;
                        display: inline-block;
                        min-width: 20px;
                    }
                }
            }  
        } 
    }
    #header3Wrapper{
        background-color: $primary-color;
        min-height: 58px;
        #header3Container{
            max-width: calc(100% - 20px);
            .mainNavBar{
                background-color: $primary-color !important;
                padding:0;             
                .bg-light{
                    background-color: $primary-color;
                }
                a{
                    color:#ffffff;
                    font-family: $display;
                    text-transform: uppercase;
                    font-size: 17px;
                    letter-spacing: 2px;    
                    padding: 18px 10px;
                    text-decoration: none;    
                    display: block;
                    &:hover{
                        background-color: $secondary-color;
                        color: $primary-color;
                    }
                }    
                li ul li a {
                    color:$primary-color;
                    display: block;
                    font-size: 14px;
                    padding: 15px 8px;
                    letter-spacing: 1px;
                }
                li.mobile-only{
                    display:none;
                    @media screen and (max-width:$break){
                        display:block;
                    }
                }
                .navbar-toggler{
                    background:$secondary-color;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
                form{
                    display: flex;
                    height: 38px;
                    vertical-align: middle;
                    align-items: center;
                    position:relative;
                    max-width: 170px;
                    width:170px;
                    @media screen and (min-width:$break){
                        margin: 10px 0 0 20px;
                    }
                    @media screen and (max-width:$break){
                        padding: 30px 0 50px 10px;
                    }   
                    [type="text"] {
                        width: 120px;
                        margin-right:2px;
                    }
                    [type="submit"]{
                        width: 30px;
                        height: 30px;
                        margin-left: 5px;
                        opacity: 0;
                        position: absolute;
                        right:0;
                        z-index:60;
                    }
                    .fa-search{
                        position: absolute;
                        right: 0;
                        color: #fff;
                        font-size: 22px;
                    }
                }
            }
        }
    }
}