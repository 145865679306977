.list-container{
    width: 33.33%;
    @media screen and (max-width:$tablet){
        width:100%;
    }      
    @media screen and (min-width:$tablet){
        margin-left: 30px;
    }
    .list-inner-container {
        height: 400px;
        overflow: scroll;
        background-color:#ffffff;
        border-top:4px solid $secondary-color;   
        border-bottom:4px solid $secondary-color;     
        padding: 20px;   
    }
    h2,
    h4{
        color:$primary-color;
        font-family: $display; 
        padding-bottom: 3px;  
        margin:0 0 10px;            
    }      
    h4{
        font-size: 20px;
    }
}