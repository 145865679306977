.cta{
    text-align:center; 
    padding:50px 0;
    h3{
        font-family:$display;
        strong{
            font-size: 38px;
            padding: 10px 0;
        }
    }
    p{
        max-width: 542px;
        margin: 20px auto 30px;
    }
    .icon-item{ 
        margin: 40px auto 0px;
        display: block;
        .icon-img img {
            width: 110px;
            height: 115px;
            margin: 0 auto;
        }
    }  
}