
// @font-face {
//     font-family: "ClassicGrotesqueW01-MdI";
//     src: url("https://static.dal.ca/etc/designs/dalhousie/clientlibs/global/default/css/fonts/2723a598-2155-4a48-a08b-d6da5d3729d7.eot?#iefix");
//     src: url("https://static.dal.ca/etc/designs/dalhousie/clientlibs/global/default/css/fonts/2723a598-2155-4a48-a08b-d6da5d3729d7.eot?#iefix") format("eot"), url("https://static.dal.ca/etc/designs/dalhousie/clientlibs/global/default/css/fonts/c460b776-24e2-47f4-a932-3ee0ae1057f5.woff") format("woff"), url("https://static.dal.ca/etc/designs/dalhousie/clientlibs/global/default/css/fonts/fa7db186-2745-4aae-9cf9-69f59bb62fe2.ttf") format("truetype"), url("https://static.dal.ca/etc/designs/dalhousie/clientlibs/global/default/css/fonts/36a863d2-2429-4da3-8447-57249c673a65.svg#36a863d2-2429-4da3-8447-57249c673a65") format("svg")
// }

body{
    font-family: Arial, Helvetica, sans-serif;
}

h1,
h2 {
    font-family: $display !important;
    color:$primary-color;
}

h1 {
    font-size: 2.8em;
    margin: 0;
}

.t7_title{
    margin: 0;
    font-style: normal;
    text-align: left;
    font-weight: 600;     
    font-size: 28px;
    letter-spacing: 1px; 
    text-transform: uppercase;
}

.t7_title-alt{
    letter-spacing: 1px; 
    text-transform:lowercase;
    font-size: 34px;
    font-style:italic;
    font-weight:400;
}

.middle{
    text-align: center;
}


p{
    line-height: 26px;
}

.table-title{
    background-color: $primary-color;
    color:#ffffff;
    font-size: 27px;
    letter-spacing: 1px;
    text-align: center;
    padding: 20px;
    text-transform: uppercase;      
    margin-bottom:0; 
    border-right: 1px solid $mid-gray;
    border-left: 1px solid $mid-gray;
}

.table-sub-title{
    background-color:darken($light-gray,8%);
    text-align: center;
    text-transform: uppercase;  
    font-size:24px;
    letter-spacing: 1px;
    padding: 18px;
    margin:0;
    font-weight: 600;
    border-right: 1px solid $mid-gray;
    border-left: 1px solid $mid-gray;
}