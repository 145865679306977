.background{
    background-image: url('https://workforcecenter.slu.edu/images/background-pattern.png');
    background-repeat: repeat;
    background-size: 250px;
    background-color: #f4f5f2;
}

.background-gray{
    background-color:$light-gray;
}

.background-white{
    background-color:#ffffff;
}