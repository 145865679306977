.thumb-link-grid{
    display:flex;
    justify-content: space-between;
    @media screen and (max-width:$tablet){
        flex-direction: column;
    }
    .thumb-link-container{
        width:45%;
        border:4px solid $secondary-color;
        height: 330px;
        @include transition(all,$duration);
        @include box-shadow(10px, 10px, 20px, rgba(0,0,0,0));
        overflow: hidden;
        position: relative;
        z-index: 50;
        @media screen and (max-width:$tablet){
            width:100%;
            margin-bottom:20px;
        }
        &:after{
            content: '';
            background:#fff;
            z-index: -2;
            position: absolute;
            left:0;
            right:0;
            top:0;
            bottom:0;
        }
        &:before { 
            content: '';
            z-index: -1;
            position: absolute;
            right:-50px;
            top:0;
            left:0;
            bottom:0;
            border-right: 50px solid transparent;
            border-bottom: 400px solid $secondary-color;
            transform: translateX(-100%);
            @include transition(all,$duration);
        }
        &:hover{
            @include box-shadow(10px, 10px, 20px, rgba(0,0,0,.1));
            @include transition(all,$duration);
            &:before{
                transform: translateX(0);
                @include transition(all,$duration);
            }
        }
        .thumb-link{ 
            text-transform:uppercase;
            h3{
                font-family:$display;
                font-size:30px;
                text-align:center;
                padding:25px 0 10px;
                margin:0;
                @media screen and (min-width:$tablet + 1 ) and (max-width:$break){
                    font-size:20px; 
                }
            }
            img{
                margin: auto;
                display: block;
                @include transform(scale(1.1)); 
                @media screen and (max-width:$tablet + 30){
                    @include transform(scale(.9)); 
                }
            }
        }
    }
}