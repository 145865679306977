footer{
    border-top:4px solid transparent;
    margin: 160px 0 0;
    position: relative;
    @media screen and (max-width:$break){  
        margin: 420px 0 0;
    }
    @media screen and (min-width:$break){
        background-color:#fff;
    }
    &:after{
        content:'';
        width: 100%;
        height: 4px;
        background-color:$primary-color;
        display: block;
        z-index: 3;
        position: absolute;
        top: -4px;
        left: 0;
        right: 0;
    }
    .footer-logo{
        position: absolute;
        z-index: 50;
        @media screen and (min-width:$break){
            top: -129px;
        }
        @media screen and (max-width:$break){  
            top: -380px;
            margin: auto;
            left: 0;
            right: 0;
        }
    }
    .footer-graphic{
        position: absolute;
        z-index: -1;
        width: 310px;
        max-width: 100%;
        right: 0;
        @include transition(all,$duration);
        @media screen and (min-width:$break){
            top:-4px;
        }
        @media screen and (max-width:$break){        
            bottom: 0;
            left: 0;
            margin: auto;
            opacity: .05;  
        }
    }
    .copyright-line {
        @media screen and (min-width:$break){
            padding:120px 0 0;
        }
        @media screen and (max-width:$break){
            text-align: center;
        }
        small {
            color:$primary-color;
            font-size: 9px;
            line-height: 19px;
            display: block;
            margin: 0 0 12px;      
        }
    }
    .subscribe{
        margin: 0 auto;
        width: 300px;
        left: 0;
        right: 0;
        top: -89px;
        position: absolute;
        text-align: center;
        font-family: $display;    
        color:$primary-color;
        text-transform: uppercase;
        z-index: 2;
        p{
            font-weight: 500;
            letter-spacing: 2px;
            margin: 0 0 4px;
        }
        .subscribe-btn{
            a{
                color:#ffffff;
                text-decoration: none;
                text-align: center;
                background-color: $primary-color;
                font-family: $display;    
                padding: 9px 0;
                font-size: 20px;
                text-transform: uppercase;
                letter-spacing: 3px;  
                display: block;              
                &:hover{
                    background-color: $secondary-color;
                    color: $primary-color;
                }
            }
        }
    }
    .col-md-4{
        @media screen and (min-width:$break){
            padding:0;
        }
    }
    .social{
        nav{
            text-align: center;
            ul{
                padding: 20px 0 10px;
                li{
                    display: inline-block;
                    margin: 0 5px;
                    a{
                        background-color:$primary-color;
                        color:#ffffff;
                        @include border-radius(50%);
                        width: 40px;
                        height: 40px;
                        display: block;
                        font-size: 20px;
                        padding: 6px 0 0;
                        &:hover{
                            background-color: $secondary-color;
                            color: $primary-color;
                        }
                    }
                }                
            }
        }
    }
    .row + .row{
        @media screen and (max-width:$break){    
            flex-direction: column-reverse;
        }
    }
    .footerConnect{
        display:flex;
        @media screen and (max-width:$handheld){
            flex-direction: column;
        }
        .column{
            width: 50%;
            @media screen and (max-width:$handheld){
                width: 100%;
                max-width: 80%;
                margin: auto;
            }
            .glyphicon{
                margin: 6px 4px 0 0;
                background: $primary-color;
                color: #fff;
                padding: 5px;
                font-size: 12px;
                @include border-radius(100%);
                width: 22px;
                height: 22px;
                margin: auto;                
            }
        }
        a,p{
            font-family: $display;
            font-size: 14px;
            color: $primary-color;
        }
        p{
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 28px);
            padding: 0 0 0 4px;
            line-height: 20px;
        }
    }
    #copyright{
        padding:15px 0 10px;
    }
    .animated {
        .footer-graphic{
            @media screen and (min-width:$break){
                top: -164px;
                @include transition(all,$duration-long);
            }
        }
    }
}