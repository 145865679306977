.bar{
    background-color:$primary-color;
    text-align: center;
    padding: 4px 0;
    font-weight: bold;
    margin-bottom: 20px;
    @media screen and (max-width:$tablet){
        padding:10px 0;
    }
    h1,h2{
        color:#ffffff;
        margin:0;
        font-style: italic;
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 1.5px;
    }
    &.yellow{
        background-color:$secondary-color;
        h1,h2 {
            color:$primary-color;
        }
    }
} 