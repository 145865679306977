.team{
    position: relative;
    padding:30px 0;
    @media screen and (max-width:$tablet){
        flex-direction:column;
    }    
    h2{
        text-align: center;
    }  
    &:after{
        content: '';
        top: 0;
        left: -1000%;
        width: 4000%;
        position: absolute;
        background-color: #e5e5e5;
        height: 100%;
        z-index: -1;
    }
    [class*="wrap"]{
        flex-wrap:wrap;
        display:flex;
        justify-content: center;
    }
    .team-member{
        width:25%; 
        text-align: center;
        margin:25px 0;
        @media screen and (max-width:$tablet){
            display:block;
            clear:both;
            width:48%;
            margin:10px 0;
        }
        @media screen and (max-width:$handheld){
            width:100%;
        }        
        img {
            border:3px solid $secondary-color;
            height:114px;
            width:114px;
            @include border-radius(50%);  
        }
        p{
            color:$primary-color;
            font-weight: bold;
            margin: 5px 0 0;
            text-transform: uppercase;
        }
        a{
            font-weight: bold;
            color:$primary-color;
            font-size: 12px;
            line-height: 18px;                
        }
        p,a{
            display: block; 
        }
    }
    &.three-column{
        .team-member{
            @media screen and (min-width:$tablet + 1 ){
                width:33.333%;
            }
        } 
    }
}
section.team {
    background-color:#e5e5e5;
}