.testimonial{
    padding:50px 0;
    h2{
        text-align: center;
        text-transform: uppercase;
    }
    .fas{
        color:$primary-color;
        font-size: 60px;
    }
    .carousel-indicators li{
        background-color:$primary-color;
    }
    .testimonial-entry{
        border:4px solid $secondary-color;
        padding:25px;
        display:flex;
        margin:50px 0;
        background:#ffffff;
        position: relative;
        align-items: center;
        @media screen and (max-width:$tablet){
            flex-direction: column-reverse;
        }
        .person{
            text-align: center;
            width: 450px;
            margin: 0 auto;
            @media screen and (max-width:$tablet){
                width:100%;
            }                  
            h2{
                font-size: 24px;
                font-weight: bold;
            }
            p{
                margin-bottom: 0;
                font-size: 15px;
            }
            img{
                border:3px solid $secondary-color;
                height:114px;
                width:114px;
                @include border-radius(50%);
            }
        }
        quote{
            color:$primary-color;
            font-family: $display;
            font-size: 26px;
            padding: 30px;
            font-weight: bold;
            position: relative;
            z-index: 50;
            &:before,
            &:after {
                content:'';
                background-image:url('https://workforcecenter.slu.edu/images/icon-quote.png');
                height:20px;
                width: 25px;
                display: inline-block; 
                background-repeat: no-repeat;
                background-size: contain;
                vertical-align: top;
                margin-top:5px;
            }
            &:before{
                margin-right:10px;
                @include transform (scaleX(-1));
            }
            &:after{
                margin-left:10px;
            }
        }
        /** 
        &:after{
            //large quote disabled 
            content:'';
            background-image:url('../images/icon-quote.png');
            height:85px;
            width: 85px;
            display: block;
            position: absolute;
            right:10%;
            top:180px; 
            z-index: 00;
            background-repeat: no-repeat;
            background-size: contain;
            opacity:.6;
            @media screen and (max-width:$tablet){
                top:250px; 
            }
        }
        **/
    }
}