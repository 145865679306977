$imgwidth:150px;
$imgwrapperwidth:$imgwidth + 60px;
.panels{
    .panel{
        display: flex;
        border-bottom:$light-gray 6px solid;
        padding: 20px 0;
        @media screen and (max-width:$handheld){
            flex-direction: column;
        }
        &:first-child{
            border-top:$light-gray 6px solid;
        }
        &.no-bottom-border {
            border-bottom:0;
            padding-bottom: 0; 
            + .panel{    
                padding: 20px 0 25px;
            }           
        }
        .panel-img{
            width:$imgwrapperwidth;
            padding-bottom: 10px;
            img{
                max-width: $imgwidth;
            }
        }
        .main{
            @media screen and (min-width:$handheld){
                width:calc(100% - #{$imgwrapperwidth});
            }
            img{
                max-width: 160px;
            }
        }        
    }
}