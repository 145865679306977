#pageStudentLogin{
    .btn-primary{
        text-transform: uppercase;
        font-family: $display;
        &:hover{
            background-color:#000;
        }
    }
    #pageHeader {
        text-align: center;
        padding: 20px 0;
    }    
    #contentModule1 .row .row {
        flex-wrap: nowrap;
        @media screen and (max-width:$tablet){
            flex-direction: column; 
            margin: 0;
        }
    }
    #existingStudentDiv{
        @media screen and (max-width:$tablet){
            padding:0;
        }
        .row{
            flex-wrap: nowrap;
            width: 100%;
        }
        .sectionHeader{
            display: none;
        }
    }
    #existingStudentDiv .col-md-6,
    #newStudentDiv{
        background-color:$offwhite;
        padding:20px;
        text-align: center;
        display: flex;
        vertical-align: middle;
        min-height: 410px;
        @media screen and (min-width:$tablet){
            flex-direction: column;
        }
        @media screen and (max-width:$tablet){
            margin: 0 0 20px;
        }
        #portalExternalLogonLinkPanel{
            padding: 100px 0 0;
            max-width: 250px;
            margin: 0 auto 20px;
        }
        #variableContentBlockPG0014{
            margin-top: 20px;
            p{
                margin:0 0 5px;
            }
        }
        h2,h3{
            font-family:$display;
            color:#000;
            font-size:20px;
        }
        .btn{
            margin: 0 auto;
            float: none;
        }
    }    
    #portalNativeLoginPanel,
    #newStudentDiv{
        @media screen and (min-width:$tablet){
             margin-left:30px;
        }
    }
    #newStudentDiv{
        flex-direction: column;
    }
    #portalNativeLoginPanel > div{
        display: none;
    }  
    div.existingStudentCredentialsContainer{
        margin-top:10px;
    }
    .forgot-credential-container{
        flex-direction: column;
        a{
            color:$mid-gray;
            margin:5px 0 2px;
        }
    }
    .card-body{
        flex:0;
        padding:0;
    }
    .info{
        position: absolute;
        width: 20px;
        height: 20px;
        right: 13px;
        top: 10px;
        z-index: 25;
        cursor:pointer;
    }
    [name="portalLogonForm"]{
        margin:auto;
    }
    .tooltip{
        background-color: #000;
        color: white;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 13px;
        z-index: 5;
        opacity: 1;
        display: none;
        top: 38px;
        width: 170px;
        right: 2px;
        position: absolute;
        margin: auto;
        img{
            position: absolute;
            width: 20px;
            top: -16px;
            right: 10px;
            height: 29px;
            vertical-align: top;
        }
        &.on {
            display: block !important;
        }
    }  
}