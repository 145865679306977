.accordion {
    .card-header{
        cursor: pointer;
        .glyphicon-plus{
            margin-top: 4px;
            font-size: 20px;
            color:$primary-color;
        }
        &:not(.collapsed) .glyphicon-plus:before {
            content:"\f068";
        }        
    }
    h3{
        margin:0;
        &:hover{
            text-decoration:underline;
        }
    }
    p {
        margin-bottom:0;
    }
}